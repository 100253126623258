import React from "react";
import facebook from "../Assets/Facebook.png";
import linkedIn from "../Assets/linkedin.png";
import twitter from "../Assets/Twitter.png";
import logo from "../Assets/Artboard-4-2.png";
function Foot() {
  return (
    <footer class=" bg-[#212121] mt-[16px] sm:mt-[72px] h-[312px] sm:h-[295px] sm:px-[128.5px]">
      <div class="container mx-[16px] sm:mx-auto w-[100%] overflow-hidden">
        <div class="sm:flex sm:mt-8 flex-col justify-center gap-[51.2px]">
          <div class="mt-8 sm:mt-0 sm:w-full h-[204px] sm:h-auto sm:px-8 flex flex-col md:flex-row sm:justify-between gap-[12px] sm:gap-[0x]">
            <div class="flex flex-col w-[343px] sm:w-[234.44px] gap-[8px] sm:gap-[10.96px]">
              <img
                src={logo}
                alt="logo"
                class="w-[142.7px] h-[32px] sm:w-[159.43px] sm:h-[35.63px]"
              />
              <span class="font-normal text-[12px] leading-[19px] tracking-wider text-white/[65%]">
                We help you Ace the Web 3 Race.
              </span>
            </div>
            <div class="hidden sm:flex flex-col w-[114px] h-[174.66px] text-center">
              <span
                class="font-bold text-[22px] leading-[33px] tracking-[0.171875px] text-white mb-[10.96px] cursor-pointer"
                onClick={() => {
                  window.location.href = "#home";
                }}
              >
                Home
              </span>
              <div class="flex flex-col gap-[16.5px]">
                <span
                  class="font-normal text-[12px] leading-[19px] tracking-wider text-white/[65%] cursor-pointer"
                  onClick={() => {
                    window.location.href = "#who-we-are";
                  }}
                >
                  About us
                </span>
                <span
                  class="font-normal text-[12px] leading-[19px] tracking-wider text-white/[65%] cursor-pointer"
                  onClick={() => {
                    window.location.href = "#services";
                  }}
                >
                  Services
                </span>
                <span
                  class="font-normal text-[12px] leading-[19px] tracking-wider text-white/[65%] cursor-pointer"
                  onClick={() => {
                    window.location.href = "#our-prospectus";
                  }}
                >
                  Our Prospectus
                </span>
                <span
                  class="font-normal text-[12px] leading-[19px] tracking-wider text-white/[65%] cursor-pointer"
                  onClick={() => {
                    window.location.href = "#testimonials";
                  }}
                >
                  Testimonials
                </span>
                <span
                  class="font-normal text-[12px] leading-[19px] tracking-wider text-white/[65%] cursor-pointer"
                  onClick={() => {
                    window.location.href = "#faq";
                  }}
                >
                  FAQs
                </span>
              </div>
            </div>
            <div class="container flex flex-col w-[229px] gap-[8px] sm:gap-[10.96px]">
              <span class="font-bold text-[16px] leading-[24px] sm:text-[22px] sm:leading-[33px] sm:tracking-[0.171875px] text-white ">
                Contact us
              </span>
              <span class="font-normal text-[12px] leading-[14px] sm:leading-[19px] tracking-wider text-white/[65%]">
                Email:- contact@buildroz.com
              </span>
              <div class="hidden sm:flex gap-[10px] mt-[10px]">
                {/*                 <img
                  src={facebook}
                  alt="fb"
                  class="w-[30px] h-[30px] cursor-pointer"
                  onClick={() => {
                    window.open("https://www.facebook.com/buildroz");
                  }}
                /> */}
                <img
                  src={linkedIn}
                  alt="linkedin"
                  class="w-[26.12px] h-[26.12px] cursor-pointer"
                  onClick={() => {
                    window.open("https://www.linkedin.com/company/buildorz");
                  }}
                />
                <img
                  src={twitter}
                  alt="twitter"
                  class="w-[30px] h-[30px] cursor-pointer"
                  onClick={() => {
                    window.open("https://twitter.com/buildorz");
                  }}
                />
              </div>
            </div>
          </div>
          <div class="ml-[30px] hidden sm:flex justify-between w-[1090px]">
            <span class=" font-medium text-[11px] leading-[13px] tracking-wider text-white/[65%] mix-blend-normal">
              © 2022 | All Rights Reserved
            </span>
          </div>

          {/* for mobile */}
          <div class="sm:hidden flex flex-col gap-[8px]">
            <span class="font-bold text-[16px] leading-[24px] sm:text-[22px] sm:leading-[33px] sm:tracking-[0.171875px] text-white ">
              Follow us
            </span>
            <div class="flex gap-[10px]">
              {/*               <img
                src={facebook}
                alt="facebook"
                class="w-[24px] h-[24px]"
                onClick={() => {
                  window.open("https://www.facebook.com/buildroz");
                }}
              /> */}
              <img
                src={linkedIn}
                alt="linkedIn"
                class="w-[24px] h-[24px]"
                onClick={() => {
                  window.open("https://www.linkedin.com/company/buildorz");
                }}
              />
              <img
                alt="twitter"
                src={twitter}
                class="w-[30px] h-[30px]"
                onClick={() => {
                  window.open("https://twitter.com/buildorz");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Foot;
