import React from "react";
import { PopupButton } from "react-calendly";
import { motion as m } from "framer-motion";

function ContactCard() {
  return (
    <m.div
      class="bg-gradient-to-r from-[#0058C6] to-[#071B5A] w-[100%] h-[180px] sm:h-[260px] flex flex-col justify-center gap-[24.5px] sm:mb-[72px] mb-[48px] rounded-[15px] sm:rounded-[25px] sm:py-[30px] py-[20px] px-[20px] "
      initial={{ opacity: 0, scale: 0.5 }}
      whileInView={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.75 }}
      viewport={{ once: true }}
    >
      <span class=" font-roboto text-white font-bold text-[22px] leading-[26px] tracking-[0.0025em] sm:text-[36px] sm:leading-[36px] sm:-tracking-tight text-center">
        Let's Discuss Your Project
      </span>
      <p class="font-roboto text-white font-normal text-[12px] leading-[19px] sm:text-[24px] sm:leading-[36px] tracking-[0.01em] text-center">
        Get free consultation, tell us about your project idea to see it turn
        into a stellar digital product.
      </p>
      <div class="font-poppins text-white sm:font-bold py-[5px] font-bold text-[16px] sm:text-[22px] leading-[24px] sm:text-[24px] sm:leading-[36px] sm:tracking-[0.02em] border-[1.5px] rounded-[34.7px] w-[210px] sm:w-[259.63px] sm:h-[47.37px] self-center">
        <PopupButton
          url="https://calendly.com/buildorz/consultation"
          rootElement={document.getElementById("root")}
          text="Talk to our experts"
          className="h-[100%] w-[100%]"
        />
      </div>
    </m.div>
  );
}

export default ContactCard;
