import React, { useState } from "react";
import logo from "../Assets/Artboard-4-2.png";
import { navLinks } from "../constants";
import { motion as m } from "framer-motion";
let gblue = "from-[#3E98FF] to-[#1D4AFF]";

function Navbar() {
  const [toggle, setToggle] = useState(true);
  const [isActive, setIsActive] = useState("Services");

  return (
    <nav className="flex justify-between items-center sm:grid sm:grid-cols-3 sm:mb-[127px] mb-[30px]">
      <img
        src={logo}
        class="w-[108px] h-[24px] sm:h-[42px] sm:w-[187.3px]"
        alt="logo"
      />
      <button
        type="button"
        class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        aria-expanded="false"
        onClick={() => {
          console.log("clicked");
          setToggle((toggle) => !toggle);
        }}
      >
        <svg
          class="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
      <div class="hidden sm:col-span-2 sm:mr-0 sm:ml-auto sm:block sm:w-auto">
        <div className="sm:flex sm:items-center text-white font-poppins sm:font-medium sm:text-[18px] sm:tracking-wide sm:space-x-[24px] w-[100%]">
          {navLinks.map((nav, index) => (
            <m.a
              key={nav.id}
              href={nav.id}
              initial={{ opacity: 0, x: -100 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 + index * 0.1 }}
              viewport={{ once: true }}
              onClick={() => {
                setIsActive(nav.title);
              }}
            >
              {nav.title}
            </m.a>
          ))}

          <m.button
            animate={{ x: [0, 50, 0] }}
            transition={{ duration: 0.5, delay: 2.32, type: "spring" }}
            viewport={{ once: true }}
            className={`${gblue} bg-gradient-to-r rounded-[44px] px-[24px] py-[12px] w-[153px] h-[51px] text-white font-poppins font-medium text-lg tracking-wide `}
            onClick={() => {
              window.location.href = "#contact-us";
            }}
          >
            Contact Us
          </m.button>
        </div>

        {/* for mobile */}
        {/* {      <div id="navbar-default">
        <ul className="hidden text-white font-poppins sm:font-medium text-[18px] tracking-wide sm:space-x-[24px]">
          <li>
            <a>Services</a>
          </li>
          <li>
            <a>Project</a>
          </li>
          <li>
            <a>Testimonials</a>
          </li>
          <li>
            <a>Partners</a>
          </li>
          <li>
            <a>FAQ</a>
          </li>
          <li>
            <button
              className={`${gblue} bg-gradient-to-r rounded-[44px] px-[24px] py-[12px] w-[153px] h-[51px] text-white font-poppins font-medium text-lg tracking-wide`}
            >
              Contact Us
            </button>
          </li>
        </ul>
      </div>} */}
      </div>

      <div
        className={`${
          toggle ? "hidden" : "block"
        } absolute top-20 right-0 my-2 w-full h-full md:hidden p-6 bg-[#111111] z-50`}
      >
        <ul className="list-none flex justify-evenly items-center flex-col w-full h-5/6">
          {navLinks.map((nav, index) => (
            <li
              key={nav.id}
              onClick={() => {
                setIsActive(nav.title);
                setToggle((toggle) => !toggle);
              }}
            >
              <a
                href={nav.id}
                className={`font-poppins text-2xl font-bold ${
                  isActive === nav.title ? "text-white" : "text-[#68696D]"
                }`}
              >
                {nav.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
