import React, { useRef } from "react";
import group from "../Assets/Group.png";
import hello from "../Assets/HELLO.png";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { motion as m } from "framer-motion";

let gblue = "from-[#3E98FF] to-[#1D4AFF]";

function ContactUs() {
  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();

    await toast.promise(
      emailjs.sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      ),
      {
        pending: "Sending you message...",
        success: "Message sent, Expect a reply in next few hours 👌",
        error: "Something went wrong 🤯",
      },

      {
        theme: "colored",
        autoClose: 5000,
        position: "top-center",
      }
    );

    document.getElementById("contact-form").reset();
  };

  return (
    <div
      class="flex sm:flex-row flex-col justify-between w-[327px] h-[497px] sm:w-auto sm:h-auto"
      id="contact-us"
    >
      <m.div
        class="flex flex-col w-[247px] h-[126px] sm:w-[551.2px] sm:h-[707.5px]"
        initial={{ opacity: 0, scale: 0.5 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.75 }}
        viewport={{ once: true }}
      >
        <span class="font-bold text-[56px] leading-[42px] sm:text-[74px] sm:leading-[51px] tracking-[0.0025em] text-white">
          SAY
        </span>
        <img src={hello} className="w-[326px] h-[96px]" />
        <img
          src={group}
          class="hidden sm:mt-[59.53px] sm:flex h-[426px] w-[551.2px]"
        />
      </m.div>
      <m.div
        class="container w-[91vw] h-[317px] sm:w-[576.62px] sm:h-[640px] bg-[#212121] sm:mt-10 rounded-[17.5744px] shadow-[17px_17px_17px_rgba(0, 0, 0, 0.12)]"
        initial={{ opacity: 0, scale: 0.5 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.75, delay: 0.5 }}
        viewport={{ once: true }}
      >
        <div class="text-white pt-[17.15px] pl-[21.91px] sm:pt-[35.15px] sm:pl-[39.91px]">
          <h2 class="font-bold text-[20.2357px] leading-[24px] sm:text-[41.0069px] sm:leading-[48px] tracking-[0.0025em]">
            For Project Inquiries
          </h2>
        </div>
        <div class="sm:pt-[37px] pt-[18px]">
          <div>
            <form
              class="flex flex-col w-[257.65px] h-[231.4px] gap-[17.41px] sm:w-auto sm:h-auto ml-[21.6px] sm:gap-[35px] sm:ml-[39.9px]"
              ref={form}
              onSubmit={sendEmail}
              id="contact-form"
            >
              <div class="flex flex-col gap-[8.7px] sm:gap-[17.61px] sm:w-[577.62px] sm:justify-center">
                <div class="xl:w-1/4 lg:w-1/2 md:w-1/2">
                  <input
                    type="text"
                    name="user_name"
                    class="w-[257.65px] h-[22.49px] sm:w-[475.67px] sm:h-[45.37px] border-b-[0.72px] sm:border-b-[1.46px] border-b-white/[30%] shadow-sm bg-transparent font-normal text-[7.94657px]
leading-[9px] sm:text-sm text-white"
                    placeholder="Name"
                  />
                </div>
                <div class="xl:w-1/4 lg:w-1/2 md:w-1/2">
                  <input
                    type="email"
                    name="user_email"
                    class="w-[257.65px] h-[22.49px] sm:w-[475.67px] sm:h-[45.37px] border-b-[0.72px] sm:border-b-[1.46px] border-b-white/[30%] shadow-sm bg-transparent font-normal text-[7.94657px]
leading-[9px] sm:text-sm text-white"
                    placeholder="Email Address"
                  />
                </div>
                <div class="xl:w-1/4 lg:w-1/2 md:w-1/2 flex sm:block">
                  <input
                    type="text"
                    name="user_phone"
                    class="w-[195.65px] h-[22.49px] sm:w-[475.67px] sm:h-[45.37px] border-b-[0.72px] sm:border-b-[1.46px] border-b-white/[30%] shadow-sm bg-transparent font-normal text-[7.94657px]
leading-[9px] sm:text-sm text-white"
                    placeholder="Contact Number(optional)"
                  />
                </div>
              </div>
              <div class="xl:w-1/4 lg:w-1/2 md:w-1/2">
                <textarea
                  name="user_message"
                  type="text"
                  class="w-[257.65px] h-[54.37px] sm:w-[475.67px] sm:h-[109.81px] border-b-[0.72px] sm:border-b-[1.46px] border-b-white/[30%] bg-transparent shadow-sm font-normal text-[7.94657px]
leading-[9px] sm:text-sm text-white flex items-start text-top"
                  placeholder="Message"
                />
              </div>

              <div class="xl:w-1/4 lg:w-1/2 md:w-1/2">
                <button
                  type="submit"
                  value="Send"
                  class={`w-[158.86px] h-[34.82px] sm:w-[327.32px] sm:h-[54.41px] ${gblue} bg-gradient-to-r rounded-[45px] text-white font-poppins sm:font-medium font-bold text-[11.5632px] leading-[17px] sm:text-[23.4px] sm:leading-[35px] tracking-[0.02em]`}
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </m.div>
    </div>
  );
}

export default ContactUs;
