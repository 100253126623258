import React from "react";
import { useMediaQuery } from "react-responsive";
import pipelineDesktop from "../Assets/pipeline_desktop.png";
import pipelineMobile from "../Assets/pipeline_mobile.png";
import { motion as m } from "framer-motion";

function ProjectPipeline() {
  const isMobile = useMediaQuery({ query: "(max-width: 680px)" });
  return (
    <div id="project-pipeline">
      <div>
        <m.h2
          className={`text-[24px] leading-[36px] text-[#1D4AFF] font-poppins font-bold sm:text-4xl sm:leading-[54px] sm:tracking-wide mb-[22px] sm:mb-[36px]`}
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.75 }}
          viewport={{ once: true }}
        >
          Project Pipeline
        </m.h2>
      </div>
      {!isMobile ? (
        <img
          src={pipelineDesktop}
          class="w-[100%] h-auto mt-[30px] mb-[50px]"
          alt="pipeline-desktop"
        />
      ) : (
        <img
          src={pipelineMobile}
          class="w-[100%] h-auto mt-[20px] mb-[25px]"
          alt="pipeline-mobile"
        />
      )}
    </div>
  );
}

export default ProjectPipeline;
