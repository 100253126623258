import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import icon1 from "../Assets/p-icon.png";
import icon2 from "../Assets/c-icon.png";
import icon3 from "../Assets/f-icon.png";
import figma from "../Assets/figma.png";
import ethereum from "../Assets/eth.png";
import polygon from "../Assets/polygon.png";
import solana from "../Assets/solana.png";
import tezos from "../Assets/tezos.png";
import bsc from "../Assets/bsc.png";
import twitter from "../Assets/Twitter.png";
import discord from "../Assets/discord.png";
import fb from "../Assets/Facebook.png";
import linkedin from "../Assets/linkedin.png";
import xd from "../Assets/xd.png";
import down from "../Assets/Arrow-down.png";
import up from "../Assets/Arrow-up.png";
import telegram from "../Assets/telegram.webp";
import "../App.css";
import { motion as m } from "framer-motion";

function Services() {
  const isMobile = useMediaQuery({ query: "(max-width: 680px)" });
  const [currentTab, setCurrentTab] = useState(0);
  const data = [
    {
      id: "1",
      name: "Development",
      info: "Whether you are looking to develop a token, NFT or a Defi project, Buildorz is the answer. We have a large team of highly skilled smart contract, Backend and frontend developers that creates a secure and scalable solution tailored exclusively for your business goals. Our team is experienced in working with most of the EVM chains like  ethereum, polygon, BSC, etc and non-EVM chains like soloana, Tezos etc.",
      icon: [ethereum, polygon, tezos, solana, bsc],
      iconText: ["Ethereum", "Polygon", "Tezos", "Solana", "BSC"],
    },
    {
      id: "2",
      name: "UI/UX Design",
      info: "With us you will find proficient UI/UX designing, our creative team of designers offers a full range of web and mobile app design services from prototyping and graphic design to pixel-perfect UI. We also have veteran Nft artists who can help you with your Nft line needs.",
      icon: [figma, xd],
      iconText: ["Figma", "Adobe XD"],
    },
    {
      id: "3",
      name: "Marketing",
      info: "Marketing is the key to building a reliable and robust business, our team of Marketing experts helps you accelerate your growth, improve the quality of your audience, and stay ahead of the competition. We expertise in Community Building, Social Media Marketing, Seo/Smo, Viral Marketing and Digital Marketing. We also help you grow your community with our network of partners and clients.",
      icon: [twitter, fb, linkedin, discord, telegram],
      iconText: ["Twitter", "Facebook", "LinkedIn", "Discord", "Telegram"],
    },
  ];

  const handleClick = (id) => {
    setCurrentTab(id);
  };

  return (
    <div class="sm:mb-[72px] mb-[32px] sm:h-[60vh]" id="services">
      <m.h2
        className="text-[24px] leading-[36px] text-[#1D4AFF] font-poppins font-bold sm:text-4xl sm:leading-[54px] sm:tracking-wide sm:mb-[36px] "
        initial={{ opacity: 0, x: -100 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.75 }}
        viewport={{ once: true }}
      >
        Services
      </m.h2>
      {!isMobile ? (
        <div>
          <div
            className="tabcontent flex flex-col justify-between left-[27vw] bg-[#212121] absolute px-[5vw] py-[30px] mr-[5vw] rounded-[15px] h-[50vh]"
            style={{
              boxSizing: "border-box",
            }}
          >
            {
              <React.Fragment>
                <h3 class="text-[36px] leading-[39px] text-white font-poppins font-bold sm:text-4xl sm:leading-[54px] sm:tracking-wide z-1">
                  {data[currentTab].name}
                </h3>
                <p class=" font-poppins font-normal text-[16px] leading-[23px] tracking-[0.0025em] text-white/[55%] z-1">
                  {data[currentTab].info}
                </p>
                <div className="flex gap-[2vw] items-end">
                  {data[currentTab].icon.map((icon) => (
                    <div className="flex-col flex items-center">
                      <img
                        class="h-[60.06px] z-1"
                        src={icon}
                        alt="tech-icons"
                      />
                      <div className="h-[10px]" />
                      <span className="text-[#9B9B9B] text-regular">
                        {
                          data[currentTab].iconText[
                            data[currentTab].icon.indexOf(icon)
                          ]
                        }
                      </span>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            }
          </div>
          <div className="tab flex sm:flex-row flex-col justify-between relative">
            <div class="flex flex-col h-[50vh] justify-center absolute left-0">
              <m.div
                class="bg-gradient-to-r from-[#BB00FF]/[90%] to-[#0029FF]/[90%] w-[25vw] h-[90px] rounded-[19.62px] flex items-center justify-center my-[20px]"
                initial={{ scale: 1 }}
                whileHover={{ scale: 1.1 }}
                transition={{ duration: 0.5, ease: "anticipate" }}
                viewport={{ once: true }}
              >
                <button
                  className={
                    currentTab === 0
                      ? "tablinks font-bold text-[24px] leading-9 text-white not-italic tracking-normal  bg-black bg-gradient-to-r from-[#BD00FF]/[25%] to-[#0029FF]/[25%] w-[24.5vw] h-[84px] rounded-[19.62px]"
                      : "tablinks font-bold text-[24px] leading-9 text-white not-italic tracking-normal  bg-black w-[24.5vw] h-[84px] rounded-[19.62px]"
                  }
                  onClick={() => handleClick(0)}
                >
                  <div className="flex justify-between max-w-[200px] ml-[5vw] items-center">
                    <img src={icon2} alt="" class="w-[32px] h-[32px]" />
                    Development
                  </div>
                </button>
              </m.div>
              <m.div
                class="bg-gradient-to-r from-[#FF0000] to-[#FF00F5] w-[25vw] h-[90px] rounded-[19.62px] flex items-center justify-center my-[20px]"
                initial={{ scale: 1 }}
                whileHover={{ scale: 1.1 }}
                transition={{ duration: 0.5, ease: "anticipate" }}
                viewport={{ once: true }}
              >
                <button
                  className={
                    currentTab === 1
                      ? "tablinks font-bold text-[24px] leading-9 text-white not-italic tracking-normal bg-black bg-gradient-to-r from-[#FF0000]/[25%] to-[#FF00F5]/[25%] w-[24.5vw] h-[84px] rounded-[19.62px]"
                      : "tablinks font-bold text-[24px] leading-9 text-white not-italic tracking-normal bg-black w-[24.5vw] h-[84px] rounded-[19.62px]"
                  }
                  onClick={() => handleClick(1)}
                >
                  <div className="flex justify-between max-w-[200px] ml-[5vw] items-center">
                    <img src={icon1} alt="" class="w-[22px] h-[22px]" />
                    UI/UX Design
                  </div>
                </button>
              </m.div>

              <m.div
                class="bg-gradient-to-r from-[#3E98FF]/[90%] to-[#1D4AFF]/[90%] w-[25vw] h-[90px] rounded-[19.62px] flex items-center justify-center my-[20px]"
                initial={{ scale: 1 }}
                whileHover={{ scale: 1.1 }}
                transition={{ duration: 0.5, ease: "anticipate" }}
                viewport={{ once: true }}
              >
                <button
                  className={
                    currentTab === 2
                      ? "tablinks font-bold text-[24px] leading-9 text-white not-italic tracking-normal  bg-black bg-gradient-to-r from-[#3E98FF]/[25%] to-[#1D4AFF]/[25%]' w-[24.5vw] h-[84px] rounded-[19.62px]"
                      : "tablinks font-bold text-[24px] leading-9 text-white not-italic tracking-normal  bg-black w-[24.5vw] h-[84px] rounded-[19.62px]"
                  }
                  onClick={() => handleClick(2)}
                >
                  <div className="flex justify-between max-w-[176px] ml-[5vw] items-center">
                    <img src={icon3} alt="" class="w-[32px] h-[32px]" />
                    Marketing
                  </div>
                </button>
              </m.div>
            </div>

            {/* </div> */}
          </div>
        </div>
      ) : (
        <div>
          <div class="flex flex-col justify-center items-center">
            <div class="bg-gradient-to-r from-[#BB00FF]/[90%] to-[#0029FF]/[90%] w-[100%] h-[68px] rounded-[28.62px] flex items-center justify-center mt-[20px]">
              <button
                className={
                  currentTab === 0
                    ? "tablinks font-bold text-[24px] leading-9 text-white not-italic tracking-normal  bg-black bg-gradient-to-r from-[#BD00FF]/[25%] to-[#0029FF]/[25%] w-[99%] h-[62px] rounded-[28px]"
                    : "tablinks font-bold text-[24px] leading-9 text-white not-italic tracking-normal  bg-black w-[99%] h-[62px] rounded-[28px]"
                }
                onClick={() => handleClick(0)}
              >
                <div className="flex justify-between w-[80vw] ml-[5vw] items-center">
                  <img src={icon2} alt="" class="w-[32px] h-[32px]" />
                  Development
                  <img
                    src={currentTab === 0 ? up : down}
                    alt=""
                    class="w-[22px] h-[22px]"
                  />
                </div>
              </button>
            </div>
            {currentTab === 0 && (
              <div
                className="tabcontent flex flex-col justify-between items-center bg-[#212121] px-[2vw] py-[2vh] h-auto w-[92%]"
                style={{
                  boxSizing: "border-box",
                }}
              >
                {
                  <React.Fragment>
                    <h3 class="text-[20px] leading-[24px] pb-[2vh] text-white font-poppins font-bold sm:text-4xl sm:leading-[40px] sm:tracking-wide">
                      {data[currentTab].name}
                    </h3>
                    <p class=" font-poppins font-normal text-justify text-[14px] leading-[18px] tracking-[0.0025em] text-white/[55%]">
                      {data[currentTab].info}
                    </p>
                    <div className="flex items-end justify-around w-[100%] gap-[2vw] mt-[10px]">
                      {data[currentTab].icon.map((icon) => (
                        <div className="flex-col flex items-center">
                          <img
                            class="h-[30.06px] z-1"
                            src={icon}
                            alt="tech-icons"
                          />
                          <div className="h-[5px]" />
                          <span className="text-[#9B9B9B] text-regular text-[12px]">
                            {
                              data[currentTab].iconText[
                                data[currentTab].icon.indexOf(icon)
                              ]
                            }
                          </span>
                        </div>
                      ))}
                    </div>
                  </React.Fragment>
                }
              </div>
            )}
            <div class="bg-gradient-to-r from-[#FF0000] to-[#FF00F5] w-[100%] h-[68px] rounded-[28.62px] flex items-center justify-center mt-[20px]">
              <button
                className={
                  currentTab === 1
                    ? "tablinks font-bold text-[18px] leading-6 text-white not-italic tracking-normal bg-black bg-gradient-to-r from-[#FF0000]/[25%] to-[#FF00F5]/[25%] w-[99%] h-[62px] rounded-[28px]"
                    : "tablinks font-bold text-[18px] leading-6 text-white not-italic tracking-normal bg-black w-[99%] h-[62px] rounded-[28px]"
                }
                onClick={() => handleClick(1)}
              >
                <div className="flex justify-between text-[24px] leading-9 w-[80vw] ml-[5vw] items-center">
                  <img src={icon1} alt="" class="w-[22px] h-[22px]" />
                  UI/UX Design
                  <img
                    src={currentTab === 1 ? up : down}
                    alt=""
                    class="w-[22px] h-[22px]"
                  />
                </div>
              </button>
            </div>

            {currentTab === 1 && (
              <div
                className="tabcontent flex flex-col justify-between items-center bg-[#212121] px-[2vw] py-[2vh] h-auto w-[92%]"
                style={{
                  boxSizing: "border-box",
                }}
              >
                {
                  <React.Fragment>
                    <h3 class="text-[20px] leading-[24px] pb-[2vh] text-white font-poppins font-bold sm:text-4xl sm:leading-[40px] sm:tracking-wide">
                      {data[currentTab].name}
                    </h3>
                    <p class=" font-poppins font-normal text-justify text-[14px] leading-[18px] tracking-[0.0025em] text-white/[55%]">
                      {data[currentTab].info}
                    </p>
                    <div className="flex flex-row w-[100%] gap-[2vw] justify-center mt-[10px]">
                      {data[currentTab].icon.map((icon) => (
                        <div className="flex-col flex items-center">
                          <img
                            class="h-[30.06px] z-1"
                            src={icon}
                            alt="tech-icons"
                          />
                          <div className="h-[5px]" />
                          <span className="text-[#9B9B9B] text-regular text-[12px]">
                            {
                              data[currentTab].iconText[
                                data[currentTab].icon.indexOf(icon)
                              ]
                            }
                          </span>
                        </div>
                      ))}
                    </div>
                  </React.Fragment>
                }
              </div>
            )}
            <div class="bg-gradient-to-r from-[#3E98FF]/[90%] to-[#1D4AFF]/[90%] w-[100%] h-[68px] rounded-[28px] flex items-center justify-center mt-[20px]">
              <button
                className={
                  currentTab === 2
                    ? "tablinks font-bold text-[24px] leading-9 text-white not-italic tracking-normal  bg-black bg-gradient-to-r from-[#3E98FF]/[25%] to-[#1D4AFF]/[25%]' w-[99%] h-[62px] rounded-[28px]"
                    : "tablinks font-bold text-[24px] leading-9 text-white not-italic tracking-normal  bg-black w-[99%] h-[62px] rounded-[28px]"
                }
                onClick={() => handleClick(2)}
              >
                <div className="flex justify-between w-[80vw] ml-[5vw] items-center">
                  <img src={icon3} alt="" class="w-[32px] h-[32px]" />
                  Marketing
                  <img
                    src={currentTab === 2 ? up : down}
                    alt=""
                    class="w-[22px] h-[22px]"
                  />
                </div>
              </button>
            </div>
            {currentTab === 2 && (
              <div
                className="tabcontent flex flex-col justify-between items-center bg-[#212121] px-[2vw] py-[2vh] h-auto w-[92%]"
                style={{
                  boxSizing: "border-box",
                }}
              >
                {
                  <React.Fragment>
                    <h3 class="text-[20px] leading-[24px] pb-[2vh] text-white font-poppins font-bold sm:text-4xl sm:leading-[40px] sm:tracking-wide">
                      {data[currentTab].name}
                    </h3>
                    <p class=" font-poppins font-normal text-justify text-[14px] leading-[18px] tracking-[0.0025em] text-white/[55%]">
                      {data[currentTab].info}
                    </p>
                    <div className="flex gap-[2vw] justify-around w-[100%] mt-[10px]">
                      {data[currentTab].icon.map((icon) => (
                        <div className="flex-col flex items-center">
                          <img
                            class="h-[30.06px] z-1"
                            src={icon}
                            alt="tech-icons"
                          />
                          <div className="h-[5px]" />
                          <span className="text-[#9B9B9B] text-regular text-[12px]">
                            {
                              data[currentTab].iconText[
                                data[currentTab].icon.indexOf(icon)
                              ]
                            }
                          </span>
                        </div>
                      ))}
                    </div>
                  </React.Fragment>
                }
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Services;
