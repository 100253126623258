import React from "react";
import vrimage from "../Assets/vr-glasses-3.png";
import { PopupButton } from "react-calendly";
import { motion as m } from "framer-motion";
let gblue = "from-[#3E98FF] to-[#1D4AFF]";

const Home = () => (
  <div className="flex flex-col sm:flex-row justify-between items-center mb-[50px] sm:mb-[121px]">
    <div className="sm:gap-[36px] gap-[24px] flex-col font-poppins flex items-center sm:items-start sm:w-[47vw]">
      <m.div
        className="text-white/[82%] font-medium text-[36px] sm:text-[4rem] text-center sm:text-left sm:"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
      >
        The catalyst for your{" "}
        <m.span className="text-white font-bold">Web 3.0</m.span> venture to
        grow and thrive.
      </m.div>
      <div className="flex flex-col sm:flex-row gap-[20px]">
        <m.button
          onClick={() => {
            window.location.href = "#contact-us";
          }}
          className={`${gblue} bg-gradient-to-r text-[20px] sm:w-[280px] sm:h-[70px] px-[48px] py-[12px] sm:p-0 rounded-[12px] text-white font-bold sm:text-[32px]`}
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          viewport={{ once: true }}
        >
          Contact Us
        </m.button>
        <m.div
          class="font-poppins text-white sm:font-bold py-[10px] font-bold text-[16px] sm:text-[22px] leading-[24px] sm:text-[24px] sm:leading-[36px] sm:tracking-[0.02em] border-[1.5px] rounded-[12px] text-[20px] sm:h-[70px] sm:px-[15px] px-[15px] py-[12px] sm:p-0 rounded-[12px] text-white font-bold sm:text-[32px] self-center p-auto"
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          <PopupButton
            url="https://calendly.com/buildorz/consultation"
            rootElement={document.getElementById("root")}
            text="Schedule a meet"
            className="h-[100%]"
          />
        </m.div>
      </div>
    </div>
    <div
      className="w-[85vw] h-[85vw] mt-[50px] sm:w-[27vw] sm:h-[27vw] rounded-[50%]"
      style={{
        position: "relative",
      }}
    >
      <m.div
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          top: "0",
          background: "linear-gradient(92.84deg, #00428D 0.38%, #001671 100%)",
          filter: "blur(60px)",
          borderRadius: "90%",
        }}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
      ></m.div>
      <m.img
        src={vrimage}
        style={{
          position: "absolute",
          top: "0",
          height: "100%",
          width: "100%",
          filter: "brightness(0.85)",
        }}
        alt="main img"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        whileHover={{ scale: 1.1 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
        viewport={{ once: true }}
      />
    </div>

    {/*<img src={vrelement} className='w-[397.15px] h-[394.58px] rounded-[194.455px] z-[-1]'/>*/}
  </div>
);

export default Home;
