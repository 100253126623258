import React, { useState } from "react";
import expand from "../Assets/expand.png";
import faq from "../Assets/amico2.png";
import active from "../Assets/expand_1.png";
import { motion as m, AnimatePresence } from "framer-motion";

function Faq() {
  const [currentTab, setCurrentTab] = useState(0);
  const data = [
    {
      id: "1",
      info: "This differs on a project-to-project basis and is completely dependent on the specific requirements of the client.",
    },
    {
      id: "2",
      info: "Buildorz is a one-stop solution for everyone, be it new projects or existing ones in need of able assistance. Be it development from scratch or redesigning, nothing is out of reach!",
    },
    {
      id: "3",
      info: "As the client, your satisfaction is our prime priority. We will start by asking you a lot of questions about your vision, likes and wants and your input and feedback will be key in every step of the process.",
    },
  ];

  const handleClick = (id) => {
    setCurrentTab(id);
  };

  return (
    <div class="mb-[48px] sm:mb-[72px]" id="faq">
      <m.h2
        className={`text-[24px] leading-[36px] text-[#1D4AFF] font-poppins font-bold sm:text-4xl sm:leading-[54px] sm:tracking-wide sm:my-[3%] mb-[24px]`}
        initial={{ opacity: 0, x: -100 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.75 }}
        viewport={{ once: true }}
      >
        FAQ
      </m.h2>

      <div class="flex gap-[65px] justify-between items-center">
        <div class="">
          <div class="container sm:px-6 mx-auto flex sm:gap-[48px]">
            <div class="space-y-8 flex flex-col sm:gap-[24px]">
              <div class="rounded-lg flex flex-col gap-[16px]">
                <div class="flex justify-between w-full">
                  <h1
                    onClick={() => handleClick(0)}
                    class="font-bold sm:w-[45vw] sm:h-auto w-[80vw] h-[57px] text-[16px] text-left leading-[19px] sm:text-[24px] sm:leading-[28px] tracking-[0.0025em] text-gray-700 dark:text-white cursor-pointer"
                  >
                    How much do you charge per project?
                  </h1>
                  {currentTab === 0 ? (
                    <img
                      src={active}
                      onClick={() => handleClick(0)}
                      class="w-[36px] h-[36px] cursor-pointer"
                    />
                  ) : (
                    <img
                      src={expand}
                      onClick={() => handleClick(0)}
                      class="w-[28px] h-[28px] cursor-pointer"
                    />
                  )}
                </div>
                {currentTab === 0 && (
                  <p class="flex gap-[22px] sm:text-sm text-gray-500 dark:text-gray-300">
                    {data[currentTab].info}
                  </p>
                )}
              </div>
              <div class=" rounded-lg">
                <div class="flex items-center justify-between w-full">
                  <h1
                    onClick={() => handleClick(1)}
                    class="focus:outline-none font-bold sm:w-[45vw] sm:h-auto w-[303px] h-[57px] text-[16px] text-left leading-[19px] sm:text-[24px] sm:leading-[28px] tracking-[0.0025em] text-gray-700 dark:text-white cursor-pointer"
                  >
                    Do you only take up new projects or do you provide services
                    for existing projects as well?
                  </h1>

                  {currentTab === 1 ? (
                    <img
                      src={active}
                      onClick={() => handleClick(1)}
                      class="w-[36px] h-[36px] cursor-pointer"
                    />
                  ) : (
                    <img
                      src={expand}
                      onClick={() => handleClick(1)}
                      class="w-[28px] h-[28px] cursor-pointer"
                    />
                  )}
                </div>
                {currentTab === 1 && (
                  <p class=" mt-6 text-sm text-gray-500 dark:text-gray-300">
                    {data[currentTab].info}
                  </p>
                )}
              </div>

              <div class="rounded-lg">
                <div class="flex items-center justify-between w-full align-top">
                  <h1
                    onClick={() => handleClick(2)}
                    class="focus:outline-none font-bold sm:w-[45vw] sm:h-auto w-[303px] h-[57px] text-[16px] text-left leading-[19px] sm:text-[24px] sm:leading-[28px] tracking-[0.0025em] text-gray-700 dark:text-white cursor-pointer"
                  >
                    How much input do I have in the process?
                  </h1>

                  {currentTab === 2 ? (
                    <img
                      src={active}
                      onClick={() => handleClick(2)}
                      class="w-[36px] h-[36px] cursor-pointer"
                    />
                  ) : (
                    <img
                      src={expand}
                      onClick={() => handleClick(2)}
                      class="w-[28px] h-[28px] cursor-pointer"
                    />
                  )}
                </div>

                {currentTab === 2 && (
                  <p class=" mt-6 text-sm text-gray-500 dark:text-gray-300">
                    {data[currentTab].info}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <img src={faq} class="hidden sm:flex h-[20vw]" />
      </div>
    </div>
  );
}

export default Faq;
