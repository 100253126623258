export const navLinks = [
  {
    title: "About us",
    id: "#who-we-are",
  },
  {
    title: "Services",
    id: "#services",
  },
  {
    title: "Our Prospectus",
    id: "#our-prospectus",
  },
  {
    title: "Partners",
    id: "#partners",
  },
  {
    title: "FAQ",
    id: "#faq",
  },
];
