import React from "react";
import pfp from "../Assets/sneh.png";
import stars from "../Assets/star-rating.png";
import pfp1 from "../Assets/19.jpeg";
import pfp2 from "../Assets/23.jpeg";
import pfp3 from "../Assets/33.jpeg";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { motion as m } from "framer-motion";

function Testimonials() {
  return (
    <div class="sm:h-auto sm:mb-[72px]" id="testimonials">
      <m.h2
        className={`text-[24px] leading-[36px] text-[#1D4AFF] font-poppins font-bold sm:text-4xl sm:leading-[54px] sm:tracking-wide mb-[3%]`}
        initial={{ opacity: 0, x: -100 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.75 }}
        viewport={{ once: true }}
      >
        Testimonials
      </m.h2>
      <div class="container px-4 mx-auto">
        <div class="hidden sm:flex flex-row -m-3 gap-[48px]">
          <m.div
            class="w-full  p-3 mt-[92px] "
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
          >
            <div class="p-6 h-full  bg-[#212121] bg-opacity-60 rounded-[16px]">
              <div class="flex flex-col justify-between h-full">
                <div class="mb-5 block">
                  <div class="flex flex-wrap mb-4 items-center -m-2">
                    <div class="w-[72px] h-[72px] rounded-[250.4px] p-2">
                      <img src={pfp1} alt="" />
                    </div>
                    <div class="w-auto p-2">
                      <h3 class="font-bold text-white text-[18px] leading-normal">
                        Chris Hurst
                      </h3>
                    </div>
                  </div>
                  <p class="text-[14px] font-normal text-white/[65%]">
                    Buildorz collaborated well and provided consistent
                    communication. They developed my Defi protocol. Their
                    communication was good. Himanshu's team worked well.
                  </p>
                </div>
              </div>
            </div>
          </m.div>
          <m.div
            class="w-full p-3 mb-[92px] "
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            viewport={{ once: true }}
          >
            <div class="p-6 h-full bg-[#212121] bg-opacity-60 rounded-[16px]">
              <div class="flex flex-col justify-between h-full">
                <div class="mb-5 block">
                  <div class="flex flex-wrap mb-4 items-center -m-2">
                    <div class="w-[72px] h-[72px] rounded-[250.4px] p-2">
                      <img src={pfp2} alt="" />
                    </div>
                    <div class="w-auto p-2">
                      <h3 class="font-bold leading-normal text-white text-[18px]">
                        Ravi Kumar
                      </h3>
                    </div>
                  </div>
                  <p class="text-[14px] font-normal text-white/[65%]">
                    I hired them for development of my gaming project. They did
                    a fantastic job. Quite professional and efficient. They are
                    the best at what they do. They delivered all the
                    requirements timely and their work required minimal tweaks.
                    Aayush was assigned as the work lead and really appreciate
                    his knowledge, understanding.
                  </p>
                </div>
              </div>
            </div>
          </m.div>
          <m.div
            class="w-full  p-3 mt-[92px]"
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 1.1 }}
            viewport={{ once: true }}
          >
            <div class="p-6 h-full bg-[#212121] bg-opacity-60 rounded-[16px]">
              <div class="flex flex-col justify-between h-full">
                <div class="mb-5 block">
                  <div class="flex flex-wrap mb-4 items-center -m-2">
                    <div class="w-[72px] h-[72px] rounded-[250.4px] p-2">
                      <img src={pfp3} alt="" />
                    </div>
                    <div class="w-auto p-2">
                      <h3 class="font-bold text-white text-[18px] leading-normal">
                        Jason Santos
                      </h3>
                    </div>
                  </div>
                  <p class="text-[14px] font-normal text-white/[65%]">
                    Buildorz team was communicative and effective in completing
                    the project. The app is now live, and we’re thrilled with
                    the final product. They were a highly efficient team to work
                    with. They were strong communicators—whenever I had an issue
                    they were available. Additionally, they were timely in
                    getting deliverables to us.
                  </p>
                </div>
              </div>
            </div>
          </m.div>
        </div>
      </div>
      {/* For mobile */}

      {/* <!-- Slider controls --> */}
      <div class="sm:hidden">
        <Carousel
          axis="horizontal"
          autoPlay={true}
          showStatus={false}
          infiniteLoop={true}
        >
          <div class=" p-3">
            <div class="p-6 h-full bg-[#212121] bg-opacity-60 rounded-[16px]">
              <div class="flex flex-col justify-between">
                <div class="mb-5 block">
                  <div class="flex flex-wrap mb-4 items-center -m-2">
                    <div class="w-[72px] h-[72px] rounded-[250.4px] p-2">
                      <img src={pfp1} alt="" />
                    </div>
                    <div class="w-auto p-2">
                      <h3 class="font-bold text-white text-[18px] leading-normal">
                        Chris hurst
                      </h3>
                    </div>
                  </div>
                  <p class="text-[14px] font-normal text-white/[65%]">
                    Buildorz collaborated well and provided consistent
                    communication. They developed my Defi protocol. Their
                    communication was good. Himanshu's team worked well.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class=" p-3">
            <div class="p-6 h-full bg-[#212121] bg-opacity-60 rounded-[16px]">
              <div class="flex flex-col justify-between h-full">
                <div class="mb-5 block">
                  <div class="flex flex-wrap mb-4 items-center -m-2">
                    <div class="w-[72px] h-[72px] rounded-[250.4px] p-2">
                      <img src={pfp2} alt="" />
                    </div>
                    <div class="w-auto p-2">
                      <h3 class="font-bold text-white text-[18px] leading-normal">
                        Ravi Kumar
                      </h3>
                    </div>
                  </div>
                  <p class="text-[14px] font-normal text-white/[65%]">
                    I hired them for development of my gaming project. They did
                    a fantastic job. Quite professional and efficient. They are
                    the best at what they do. They delivered all the
                    requirements timely and their work required minimal tweaks.
                    Aayush was assigned as the work lead and really appreciate
                    his knowledge, understanding.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class=" p-3">
            <div class="p-6 h-full bg-[#212121] bg-opacity-60 rounded-[16px]">
              <div class="flex flex-col justify-between h-full">
                <div class="mb-5 block">
                  <div class="flex flex-wrap items-center mb-4 -m-2">
                    <div class="w-[72px] h-[72px] rounded-[250.4px] p-2">
                      <img src={pfp3} alt="" />
                    </div>
                    <div class="w-auto p-2">
                      <h3 class="font-bold text-white text-[18px] leading-normal">
                        Jason Santos
                      </h3>
                    </div>
                  </div>
                  <p class="text-[14px] font-normal text-white/[65%]">
                    Buildorz team was communicative and effective in completing
                    the project. The app is now live, and we’re thrilled with
                    the final product. They were a highly efficient team to work
                    with. They were strong communicators—whenever I had an issue
                    they were available. Additionally, they were timely in
                    getting deliverables to us.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
}

export default Testimonials;
