import React from "react";
import "./App.css";

import Navbar from "./Components/navbar";
import Home from "./Components/home";
import Problem from "./Components/problem";
import Intro from "./Components/intro";

import Business from "./Components/Business";
import ContactCard from "./Components/ContactCard";
import Partners from "./Components/Partners";
import Services from "./Components/Services";
import Foot from "./Components/Foot";
import Testimonials from "./Components/Testimonials";

import Faq from "./Components/Faq";
import ContactUs from "./Components/ContactUs";
import ProjectPipeline from "./Components/ProjectPipeline";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <div
        style={{
          position: "absolute",
          width: "280px",
          height: "140px",
          left: "0px",
          top: "0px",

          background: "rgba(0, 19, 98, 0.21)",
          filter: "blur(43.3628px)",
        }}
      />
      <div className="m-[16px] sm:mx-[5vw] sm:mt-[40px]">
        <Navbar />
        <Home />
        <Problem />
        <Intro />
        {/* Services section */}
        <Services />
        {/* project pipeline section*/}

        <ContactCard />
        {/* Business model section*/}
        <Business />
        {/* Partners section*/}
        <Partners />
        <ProjectPipeline />
        {/* testimonials section */}
        <Testimonials />

        {/* FAQ section */}
        <Faq />
        {/* Form section */}
        <ContactUs />
      </div>

      {/* Footer section */}
      <Foot />
      <ToastContainer />
    </div>
  );
}

export default App;
