import React from "react";
import hami from "../Assets/hami.png";
import stackos from "../Assets/stackos-logo.png";
import pinksale from "../Assets/pinksale.png";
import quillaudits from "../Assets/quillaudits.png";
import verasity from "../Assets/verasity.png";
import huobi from "../Assets/huobi.png";
import { motion as m } from "framer-motion";

function Partners() {
  return (
    <div class="sm:mb-[72px] mb-[48px]" id="partners">
      <m.h2
        className={`text-[24px] leading-[36px] text-[#1D4AFF] font-poppins font-bold sm:text-4xl sm:leading-[54px] sm:tracking-wide mb-[4%]`}
        initial={{ opacity: 0, x: -100 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.75 }}
        viewport={{ once: true }}
      >
        Partners
      </m.h2>
      <div class="flex">
        <div
          class="w-1/3 h-[85.5px] sm:h-[176px] text-[36px] border-[0.82px] sm:border-[2px] border-[#3D3D3D] items-center justify-center flex cursor-pointer"
          onClick={() => {
            window.open("https://www.stackos.io/", "_blank");
          }}
        >
          <m.img
            class=" h-[20.31px] sm:h-[45px] brightness-0 invert"
            src={stackos}
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
            viewport={{ once: true }}
          />
        </div>
        <div
          class="w-1/3 h-[85.5px] sm:h-[176px] text-[36px] border-[0.82px] sm:border-[2px] border-[#3D3D3D] items-center justify-center flex cursor-pointer"
          onClick={() => {
            window.open(
              "https://audits.quillhash.com/smart-contract-audit",
              "_blank"
            );
          }}
        >
          <m.img
            class=" h-[44.31px]  sm:h-[100px] brightness-0 invert"
            src={quillaudits}
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3, delay: 0.2 }}
            viewport={{ once: true }}
          />
        </div>
        <div
          class="w-1/3 h-[85.5px] sm:h-[176px] text-[36px] border-[0.82px] sm:border-[2px] border-[#3D3D3D] items-center justify-center flex cursor-pointer"
          onClick={() => {
            window.open("https://app.hami.live/", "_blank");
          }}
        >
          <m.img
            class=" h-[36.31px] sm:h-[80px]"
            src={hami}
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3, delay: 0.5 }}
            viewport={{ once: true }}
          />
        </div>
      </div>
      <div class="flex">
        <div
          class="w-1/3 h-[85.5px] sm:h-[176px] text-[36px] border-[0.82px] sm:border-[2px] border-[#3D3D3D] items-center justify-center flex cursor-pointer"
          onClick={() => {
            window.open("https://www.huobi.com/en-us/", "_blank");
          }}
        >
          <m.img
            class=" h-[36.31px] sm:h-[80px] brightness-0 invert"
            src={huobi}
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3, delay: 0.8 }}
            viewport={{ once: true }}
          />
        </div>
        <div
          class="w-1/3 h-[85.5px] sm:h-[176px] text-[36px] border-[0.82px] sm:border-[2px] border-[#3D3D3D] items-center justify-center flex cursor-pointer"
          onClick={() => {
            window.open("https://www.pinksale.finance/", "_blank");
          }}
        >
          <m.img
            class="h-[36.31px] sm:h-[80px]"
            src={pinksale}
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3, delay: 1 }}
            viewport={{ once: true }}
          />
        </div>
        <div
          class="w-1/3 h-[85.5px] sm:h-[176px] text-[36px] border-[0.82px] sm:border-[2px] border-[#3D3D3D] items-center justify-center flex cursor-pointer"
          onClick={() => {
            window.open("https://verasity.io/", "_blank");
          }}
        >
          <m.img
            class="h-[36.31px] sm:h-[80px] brightness-0 invert"
            src={verasity}
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3, delay: 1.2 }}
            viewport={{ once: true }}
          />
        </div>
      </div>
    </div>
  );
}

export default Partners;
