import React from "react";
import TM from "../Assets/T&M.png";
import Grp from "../Assets/services.svg";
import business from "../Assets/investor.svg";
import concept from "../Assets/hiring.svg";
import investor from "../Assets/earn.svg";
import "../App.css";
import { motion as m } from "framer-motion";

const container = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.3, delay: 0.3, duration: 1 },
  },
};

const child = {
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.75 },
  },
  hidden: {
    opacity: 0,
    x: -150,
    transition: { duration: 0.75 },
  },
};

function Business() {
  const [currTab, setCurrTab] = React.useState(0);
  const data = [
    {
      image: TM,
      title: "‘Skip’ card",
      description: `For projects breaking new ground in Web3, In the absence of core development expertise building the right team becomes really difficult. We take this headache from you and provide you with the best support with the best talents out there.`,
    },
    {
      image: Grp,
      title: "Diverse Array of Services",
      description: `It is common for ongoing projects to face obstacles with development and/or scaling. Our expertise and networks can help you overcome this hurdle with ease. We offer a diverse array of Web 3.0-focussed services which can be tailored to fit your exact requirements.`,
    },
    {
      image: business,
      title: "Cluster of Potential",
      description: `If you are looking to invest in a Web 3.0 venture, Buildorz is the perfect scouting ground. Here you can find a diverse pool of projects to choose from. Further, initial scrutiny by our experts ensures the best quality.`,
    },
    {
      image: concept,
      title: "Become a Buildor",
      description: `For projects breaking new ground in Web3, In the absence of core
          development expertise building the right team becomes really
          difficult. We take this headache from you and provide you with
          the best support with the best talents out there.`,
    },
    {
      image: investor,
      title: "Quid Pro Quo",
      description: `Become an affiliate advisor at Buildorz.
      If you know of a project/founder who is facing issues in the development or scaling of their project, Buildorz can be the right fit for them and for you. 
      We offer a monetary incentive to people who can connect us to an innovator with an idea or an existing campaign that can benefit from our services.
      `,
    },
  ];
  const handleClick = (index) => {
    setCurrTab(index);
  };
  return (
    <div class="sm:mb-[72px] mb-[48px]" id="our-prospectus">
      <m.h2
        className="text-[24px] leading-[36px] text-[#1D4AFF] font-poppins font-bold sm:text-4xl sm:leading-[54px] sm:tracking-wide mb-[24px] sm:mb-[36px]"
        initial={{ opacity: 0, x: -100 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.75 }}
        viewport={{ once: true }}
      >
        Our Prospectus
      </m.h2>
      <div class="h-[664px] sm:h-auto">
        {/* tabs */}
        <m.div
          class="no-scrollbar flex justify-start items-start mb-[32px] tabs overflow-scroll h-[40px] sm:overflow-hidden sm:h-auto"
          variants={container}
        >
          <m.button
            className={
              currTab === 0
                ? "font-bold sm:text-[24px] sm:leading-7 not-italic tracking-wide text-white underline decoration-[3px] sm:decoration-[3px] decoration-white sm:mr-[24px] text-[18px] leading-[20px] mr-[12px] whitespace-nowrap"
                : "font-bold sm:text-[24px] sm:leading-7 text-white/[40%] not-italic tracking-wide decoration-[5px] decoration-white sm:mr-[24px] text-[18px] leading-[20px] mr-[12px] whitespace-nowrap"
            }
            onClick={() => handleClick(0)}
            variants={child}
          >
            For Founders
          </m.button>
          <m.button
            className={
              currTab === 1
                ? "font-bold sm:text-[24px] sm:leading-7 not-italic tracking-wide text-white underline decoration-[3px] sm:decoration-[3px]  decoration-white sm:mr-[24px] text-[18px] leading-[20px] mr-[12px] whitespace-nowrap"
                : "font-bold sm:text-[24px] sm:leading-7 text-white/[40%] not-italic tracking-wide decoration-[5px] decoration-white sm:mr-[24px] text-[18px] leading-[20px] mr-[12px] whitespace-nowrap"
            }
            onClick={() => handleClick(1)}
            variants={child}
          >
            For Projects
          </m.button>
          <m.button
            className={
              currTab === 2
                ? "font-bold sm:text-[24px] sm:leading-7 not-italic tracking-wide text-white underline decoration-[3px] sm:decoration-[3px] decoration-white sm:mr-[24px] text-[18px] leading-[20px] mr-[12px] whitespace-nowrap"
                : "font-bold sm:text-[24px] sm:leading-7 text-white/[40%] not-italic tracking-wide decoration-[5px] decoration-white sm:mr-[24px] text-[18px] leading-[20px] mr-[12px] whitespace-nowrap"
            }
            onClick={() => handleClick(2)}
            variants={child}
          >
            For Investors
          </m.button>
          <m.button
            className={
              currTab === 3
                ? "font-bold sm:text-[24px] sm:leading-7 not-italic tracking-wide text-white underline decoration-[3px] sm:decoration-[3px] decoration-white sm:mr-[24px] text-[18px] leading-[20px] mr-[12px] whitespace-nowrap"
                : "font-bold sm:text-[24px] sm:leading-7 text-white/[40%] not-italic tracking-wide decoration-[5px] decoration-white sm:mr-[24px] text-[18px] leading-[20px] mr-[12px] whitespace-nowrap"
            }
            onClick={() => handleClick(3)}
            variants={child}
          >
            We Are Hiring
          </m.button>
          <m.button
            className={
              currTab === 4
                ? "font-bold sm:text-[24px] sm:leading-7 not-italic tracking-wide text-white underline decoration-[3px] sm:decoration-[3px]  decoration-white sm:mr-[24px] text-[18px] leading-[20px] mr-[12px] whitespace-nowrap"
                : "font-bold sm:text-[24px] sm:leading-7 text-white/[40%] not-italic tracking-wide decoration-[5px] decoration-white sm:mr-[24px] text-[18px] leading-[20px] mr-[12px] whitespace-nowrap"
            }
            onClick={() => handleClick(4)}
            variants={child}
          >
            Earn With Buildorz
          </m.button>
        </m.div>

        {/* tab content- tabs not working now */}
        <div className="tabcontent">
          <div
            className="tabs__tab flex flex-col sm:flex-row gap-[24px] sm:gap-[80px] h-[360px] items-center"
            id="tab_1"
            data-tab-info
          >
            <img src={data[currTab].image} class="h-[356px] sm:w-[320px]" />
            <div className="flex flex-col gap-[24px]">
              <span class="font-bold text-[24px] leading-[36px] tracking-[0.0025em] sm:text-[24px] sm:leading-9 text-white not-italic sm:tracking-wide">
                {data[currTab].title}
              </span>
              <p class=" h-[144px] sm:pr-[25%] font-normal text-[16px] leading-6 text-white/[55%] not-italic tracking-wide">
                {data[currTab].description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Business;
