import React from "react";
import group327 from "../Assets/Group-327.png";
import group329 from "../Assets/Group-329.png";
import group328 from "../Assets/Group-328.png";
import { motion as m } from "framer-motion";

const Problem = () => (
  <div class="mb-[32px] sm:mb-[64px]">
    <m.h2
      className={`text-[24px] leading-[36px] text-[#1D4AFF] font-poppins font-bold sm:text-4xl sm:leading-[54px] sm:tracking-wide mb-[24px] sm:mb-[52px]`}
      initial={{ opacity: 0, x: -100 }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.75 }}
      viewport={{ once: true }}
    >
      The Problem
    </m.h2>
    <div className=" flex justify-between mx-[12px] sm:mx-11 mb-[10px]">
      <m.img
        className="w-[75px] h-[75px] sm:w-[159.72px] sm:h-[152.42px]"
        src={group327}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.75, ease: "circOut" }}
        viewport={{ once: true }}
      />
      <m.img
        className="w-[75px] h-[75px] sm:w-[159.72px] sm:h-[152.42px]"
        src={group328}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.75, ease: "circOut", delay: 0.25 }}
        viewport={{ once: true }}
      />
      <m.img
        className="w-[75px] h-[75px] sm:w-[159.72px] sm:h-[152.42px]"
        src={group329}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.75, ease: "circOut", delay: 0.5 }}
        viewport={{ once: true }}
      />
    </div>
    <m.div
      className="flex justify-between text-white font-bold text-[14px] leading-[21px] sm:text-[24px] sm:leading-[36px] tracking-[0.0lem] text-center"
      initial={{ opacity: 0, x: -100 }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.75 }}
      viewport={{ once: true }}
    >
      <span className="max-w-[101px] sm:max-w-[250px]">
        Ideas do not reach development stage
      </span>
      <span className="max-w-[101px] sm:max-w-[280px]">
        Ideas do not complete development
      </span>
      <span className="max-w-[101px] sm:max-w-[250px]">
        Ideas are actually launched
      </span>
    </m.div>
  </div>
);

export default Problem;
