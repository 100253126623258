import React from "react";
import meetingpic from "../Assets/amico.png";
import { motion as m } from "framer-motion";

const Intro = () => (
  <div class="sm:mb-[72.8px] mb-[32px]" id="who-we-are">
    <m.h2
      className={`text-[24px] leading-[36px] text-[#1D4AFF] font-poppins font-bold sm:text-4xl sm:leading-[54px] sm:tracking-wide mb-[4%]`}
      initial={{ opacity: 0, x: -100 }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.75 }}
      viewport={{ once: true }}
    >
      Who We Are?
    </m.h2>
    <div class="flex justify-between align-middle w-[100%]">
      <m.img
        src={meetingpic}
        class="hidden sm:flex sm:w-[458.51px] sm:h-[382.16px]"
        initial={{ opacity: 0, y: -100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.75 }}
        viewport={{ once: true }}
      />
      <m.div
        class=" flex flex-col justify-between gap-[24px] sm:max-h-[200px] sm:max-w-[674px]"
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.75 }}
        viewport={{ once: true }}
      >
        <span class="font-poppins text-white font-bold text-[24px] leading-[36px] sm:text-[24px] sm:leading-[36px] tracking-[0.0lem]">
          Buildorz was set up with one simple goal: A one-stop solution for all
          things Web3.
        </span>
        <span class=" text-white/[0.65] sm:font-normal sm:text-[16px] sm:leading-[24px] tracking-[0.0lem]">
          Since the Web3 terrain is new and mostly un-investigated, foresight is
          key in cracking it. Our team is armed with seasoned Web developers,
          Designers, Marketing executives and Legal experts. Experience and
          innovation come together at Buildorz, ensuring seamless Development,
          Operational and Scaling support for our clients’ projects.{" "}
        </span>
      </m.div>
    </div>
  </div>
);

export default Intro;
